import { Avatar, Box } from "@mui/material";
import React, { useEffect } from "react";
import { ExDropdown, ExMenuItem } from "../components/dropdown";
import { UserManagementPopup } from "../users-management-popup/UsersManagementPopup";
import { Icon } from "@iconify/react";
import { dispatch, useSelector } from "src/redux/store";
import { USER_ROLE } from "src/config";
import { styled } from "@mui/system";
import { ConnectionList } from "../connections/ConnectionList";
import {
  fetchCompanies,
  setSelectedConnection,
} from "src/redux/slices/connections";
import { ConnectionGreen, UserIcon, LicenseIcon, CancelIcon } from "src/assets";
import WarningDialog from "src/components/table/WarningDialog";
import LicensePopup from "./LicensePopup";
import { ConnectionsState } from "src/@types/companies";
import { selectedTemplate } from "src/redux/slices/templateSelected";
// import { setIsFirst } from "src/redux/slices/settingStore";

const ConnectionWrapper = styled("section")({
  position: "absolute" as "absolute",
  top: "50%",
  left: "92%",
  transform: "translate(-50%, -50%)",
});

export const ConnectionMenu = () => {
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const [isOpenUserManagement, setIsOpenUserMangament] = React.useState(false);
  const [isOpenLicense, setIsOpenLicense] = React.useState(false);
  const [isOpenDeleteLicense, setIsOpenDeleteLicense] = React.useState(false);
  const { connections, selectedConnection }: ConnectionsState = useSelector(
    (state) => state.connections
  );

  const addDataState = useSelector((state) => state.addConnection);
  const editDataState = useSelector((state) => state.editConnection);
  const noneRegeister = "未登録";
  const [nameShowConnection, setNameShowConnection] =
    React.useState(noneRegeister);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [addDataState.addData, editDataState.addData]);

  useEffect(() => {
    if (connections.total === 0) {
      setNameShowConnection(noneRegeister);
    } else if (selectedConnection === undefined) {
      // EX3-1275
      let useLocal = false;
      const localConnection = localStorage.getItem("selectedConnection");

      connections.data.forEach((connection) => {
        if (localConnection === JSON.stringify(connection.company_id)) {
          dispatch(setSelectedConnection(connection));
          useLocal = true;
        }
      });
      if (!useLocal) {
        dispatch(setSelectedConnection(connections.data[0]));
      }
    } else {
      dispatch(
        setSelectedConnection(
          connections.data.find(
            (conn) => conn.company_id === selectedConnection.company_id
          )
        )
      );
    }
  }, [connections, selectedConnection]);

  useEffect(() => {
    if (selectedConnection !== undefined) {
      setNameShowConnection(selectedConnection.company_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection]);

  return (
    <React.Fragment>
      <ExDropdown
        title="接続先"
        subtitle={
          nameShowConnection == "" ? "接続先 未作成" : nameShowConnection
        }
        isOpen={isOpenMenu}
        onToggle={(open) => setIsOpenMenu(open)}
        sx={{
          ".MuiMenu-paper": {
            maxWidth: "185px",
          },
        }}
      >
        <ExMenuItem
          sx={{
            position: "relative",
            borderBottom: "1px solid #F6EFEF",
            ":hover": {
              ".connection-list": {
                display: "block",
              },
            },
          }}
        >
          <Avatar
            variant="square"
            style={{ width: 15, height: 15 }}
            src={ConnectionGreen}
          />
          <Box sx={{ marginLeft: "4px", fontSize: "14px" }}>接続先</Box>
          <ConnectionWrapper>
            <Icon icon="fa6-solid:angle-right" color="#6E6E6E" />
          </ConnectionWrapper>
          <ConnectionList
            isAdd={connections.total === 0}
            itemSelected={selectedConnection}
            connections={connections.data}
            onSelectId={(item) => {
              setIsOpenMenu(false);
              if (item.company_id !== selectedConnection?.company_id) {
                dispatch(selectedTemplate(null));
                // dispatch(setIsFirst(true));
              }
              dispatch(setSelectedConnection(item));
            }}
          />
        </ExMenuItem>

        <ExMenuItem
          sx={{
            borderBottom: "1px solid #F6EFEF",
          }}
          onClick={() => {
            setIsOpenMenu(false);
            setIsOpenUserMangament(true);
          }}
          disabled={
            !(
              !!selectedConnection &&
              selectedConnection.role_id !== USER_ROLE.basic &&
              selectedConnection.can_add &&
              selectedConnection.company_name !== ""
            )
          }
        >
          <UserIcon width={15} height={15} color="#6E6E6E" />
          <Box sx={{ marginLeft: "4px", fontSize: "14px", color: "#6E6E6E" }}>
            ユーザー一覧
          </Box>
        </ExMenuItem>

        {!!selectedConnection &&
          selectedConnection.role_id === USER_ROLE.contractor &&
          !Boolean(selectedConnection.is_trial) && (
            <ExMenuItem
              sx={{
                borderBottom: "1px solid #F6EFEF",
              }}
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenLicense(true);
              }}
            >
              <LicenseIcon width={15} height={15} color="#6E6E6E" />
              <Box
                sx={{ marginLeft: "4px", fontSize: "14px", color: "#6E6E6E" }}
              >
                ライセンス登録・確認
              </Box>
            </ExMenuItem>
          )}

        {!!selectedConnection &&
          // EX3-798: show cancel service license when don't have license
          selectedConnection.role_id === USER_ROLE.contractor &&
          Boolean(selectedConnection.has_license) && (
            <ExMenuItem
              sx={{
                borderBottom: "1px solid #F6EFEF",
              }}
              onClick={() => {
                setIsOpenMenu(false);
                setIsOpenDeleteLicense(true);
              }}
            >
              <CancelIcon width={15} height={15} color="#6E6E6E" />
              <Box
                sx={{ marginLeft: "4px", fontSize: "14px", color: "#6E6E6E" }}
              >
                ライセンスの解約
              </Box>
            </ExMenuItem>
          )}
      </ExDropdown>
      {!!selectedConnection &&
        selectedConnection.role_id !== USER_ROLE.basic && (
          <UserManagementPopup
            connection={selectedConnection}
            open={isOpenUserManagement}
            onClose={() => setIsOpenUserMangament(false)}
          />
        )}
      {isOpenLicense && (
        <LicensePopup
          isOpenDialog={isOpenLicense}
          setIsOpenDialog={setIsOpenLicense}
        />
      )}
      {isOpenDeleteLicense && (
        <WarningDialog
          onClickOk={() => setIsOpenDeleteLicense(false)}
          title="ライセンスの解約"
          message="EXtelligenceのサービスメニューより解約をして下さい。"
        />
      )}
    </React.Fragment>
  );
};
